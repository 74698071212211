import './css/App.css';
import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { isMobile } from "react-device-detect";

const ultimateLogo = new URL("./images/MicrosoftTeams-image.png", import.meta.url);
const ubMan = new URL('./images/UB-full.png', import.meta.url);
const underHire = new URL("./images/underline.png", import.meta.url);

function App() {
  console.log(isMobile)
  return (
    <div className="App">
      <header>
        <div className={ isMobile ? "ultimate-logo-mobile" : "ultimate-logo"}>
          <a href='https://imposition.com/' className='home_link'>
            <img src={ultimateLogo} />
          </a>
        </div>
      </header>
      
      <header>
          <h1 className={ isMobile ? "main-title-mobile" : 'main-title'}>
            <mark>
              I'm looking for work.
              <br/>
            </mark>
            Hire me!
          </h1>
          <img style={{ marginLeft: isMobile ? '0px' : '110px', width: isMobile ? '140px' : '294px' }} src={underHire} />
      </header>

      <div className='main-content'>
        <img src={ubMan} />
      </div>

      <div className={ isMobile ? 'main-functions-mobile' : 'main-functions'}>
        <h4>{isMobile ? 'Bindery Automation Expert' : 'Bindery Automation Expert______________'}</h4>
        <h4>{isMobile ? 'Specialized in +55 finishing devices' : 'Specialized in +55 finishing devices_______'}</h4>
        <h4>{isMobile ? '14 years of automation experience' : '14 years of automation experience________'}</h4>
        <h4>{isMobile ? 'Fluent in JDF' : 'Fluent in JDF____________________________'}</h4>
        <h4>{isMobile ? 'Available 24/7/365' : 'Available 24/7/365______________________'}</h4>
        
        <div>
          <a style={{ marginLeft: 10 }} href='https://www.linkedin.com/in/ultimateub/'>
            <LinkedInIcon className='icons' />
            <h4 style={{ display: 'inline-block', marginLeft: '10px' }}>
                LinkedIn
            </h4>
          </a>
        </div>
        
        <div>
          <a style={{ marginLeft: 10 }} href='https://www.facebook.com/UltimateUB'>
            <FacebookIcon className='icons' />
            <h4 style={{ display: 'inline-block', marginLeft: '10px' }}>
                Facebook
            </h4>
          </a>
        </div>
        
        <div>
          <a style={{ marginLeft: 10 }} href='https://www.instagram.com/ultimate_ub/'>
            <InstagramIcon className='icons' />
            <h4 style={{ display: 'inline-block', marginLeft: '10px' }}> 
                Instagram
            </h4>
          </a>
        </div>
        

        <h5>
          Interview me at booth N848
          <br/>
          PRINTING United, Las Vegas (USA)
          <br/>
          From October 19-21, 2022
        </h5>

        <h5 style={{ color: '#FB4F14' }}>
          Book a slot on —› <a href='https://imposition.com/event/printing-united-2022/' style={{ color: '#FB4F14', textDecoration: 'none' }}>Imposition.com</a>
        </h5>
        <br/>
        <br/>
      </div>
    </div>
  );
}

export default App;
